
  import VueSlickCarousel from 'vue-slick-carousel'

  export default {
    components: {
      VueSlickCarousel,
    },
    data() {
      return  {
        settings: {
          arrows: false,
          dots: false,
          slidesToShow: 3,
          autoplay: false,
          responsive: [
            {
              breakpoint: 900,
              settings: {
                arrows: true,
                slidesToShow: 1,
                centerMode: true,
                variableWidth: true,
                infinite: false,
              }
            }
          ]
        }
      }
    },
    props: {
      blok: {
        type: Object,
        default: () => {}
      }
    },
    methods: {
      showNext() {
        this.$refs.carousel.next()
      },
      showPrev() {
        this.$refs.carousel.prev()
      },
    }
  }
